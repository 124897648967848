import UIkit from 'uikit';
import { cookieBot } from '@ambitiondev/vue-cookiebot';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { VueReCaptcha } from 'vue-recaptcha-v3';
//import VueGtag from 'vue-gtag';
//console.log(process.env.VUE_APP_RECAPTCHA_PUBLIC);

createApp(App)
    .use(cookieBot, {cookieBotId: 'b34a665c-4663-47ac-bf34-4107a582da21',})
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC })
    //.use(VueGtag , { appName:"SanitasContest", pageTrackerScreenviewEnabled:true, config:{id:process.env.VUE_APP_GA_ID,params: {anonymize_ip: true}}})
    .use(store).use(router).mount('#app')
