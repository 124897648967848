import Qs from 'qs';
const axios = require('axios');

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers = {
	'Cache-Control': 'no-cache',
	'Pragma': 'no-cache',
	'Expires': '0',
};

import { createStore } from 'vuex'

export default createStore({
	state: {
		api: {
			config: process.env.VUE_APP_API_CONFIG_URL,
			winners: process.env.VUE_APP_API_WINNERS_URL,
			contact: process.env.VUE_APP_API_CONTACT_URL,
			participate: process.env.VUE_APP_API_PARTICIPATE_URL,
		},
		winners: [],
		contestDates: {
			contest: process.env.VUE_APP_DATES_CONTEST,
			draw: process.env.VUE_APP_DATES_DRAW,
		},
		hasFinished: null,
		hasAnswertheQuestion:null,
		answeredQuestionValue:null
	},
	mutations: {
		setFinished (state, _data) {
			state.hasFinished = _data;
		},
		setWinners (state, _data) {
			state.winners = _data;
		},
		setAPI (state, _data) {
			state.api.config = _data.config;
			state.api.contact = _data.contact;
			state.api.participate = _data.participate;
		},
		setContestDates (state, _data) {
			state.contestDates.contest = _data.contest;
			state.contestDates.draw = _data.draw;
		},
		setAnswertheQuestion (state, _data) {
			state.hasAnswertheQuestion = _data;
		},
		setAnsweredQuestionValue (state,_data){
			state.answeredQuestionValue = _data;
		}
	},
	actions: {
		init ({ dispatch }) {
			dispatch('checkFinished');
		},
		checkFinished ({ commit, dispatch, state }) {
			const config = {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			};
			var params = {
				'action': 'config',
				'token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
			};
			axios
				.post(state.api.config, Qs.stringify(params), config)
				.then((response) => {
					if (response.data.finished) {
						commit('setFinished', true);
						//setTimeout(() => {
							dispatch('checkWinners');
						//}, 60000);
					} else {
						commit('setFinished', false);
						setTimeout(() => {
							dispatch('checkFinished');
						}, 60000);
					}
				})
				.catch(() => {
					setTimeout(() => {
						dispatch('checkFinished');
					}, 60000);
				})
			;
		},
		checkWinners ({ commit, dispatch, state }) {
			const config = {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			};
			var params = {
				'action': 'winners',
				'token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
			};
			axios
				.post(state.api.config, Qs.stringify(params), config)
				.then((response) => {
					if (response.data.winners.length > 0) {
						commit('setWinners', response.data.winners);
					} else {
						setTimeout(() => {
							dispatch('checkWinners');
						}, 60000);
					}
				})
				.catch(() => {
					setTimeout(() => {
						dispatch('checkWinners');
					}, 60000);
				})
			;
		}
	},
	getters: {
		getAPI: (state) => {
			return state.api;
		},
		getFinished (state) {
			return state.hasFinished;
		},
		getWinners: (state) => {
			return state.winners;
		},
		getContestDates: (state) => {
			return state.contestDates;
		},
		getAnswertheQuestion: (state) => {
			return state.hasAnswertheQuestion;
		},
		getAnsweredQuestionValue: (state) => {
			return state.answeredQuestionValue;
		}
	}
})
