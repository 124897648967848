<template>
<div id="app" class="uk-height-viewport">
	<div class="header">
		<div class="uk-container uk-flex uk-flex-left">
			<div class="menu-wrap">
				<div class="menu-toggle" v-show="!openNav" v-on:click="toggleMenu()">
					<svg viewBox="0 0 40 40" width="40" height="40">
						<rect y="0" width="40" height="8" rx="3" style="fill:rgb(255,255,255)"></rect>
						<rect y="16" width="40" height="8" rx="3" style="fill:rgb(255,255,255)"></rect>
						<rect y="32" width="40" height="8" rx="3" style="fill:rgb(255,255,255)"></rect>
					</svg>
				</div>
				<div class="menu-toggle" v-show="openNav" v-on:click="toggleMenu()">
					<svg viewBox="0 0 40 40" width="40" height="40">
						<line x1="0" y1="40" x2="40" y2="0" stroke="white" stroke-width="6"></line>
						<line x1="0" y1="0" x2="40" y2="40" stroke="white" stroke-width="6"></line>
					</svg>
				</div>
				<div class="menu-nav" v-show="openNav">
					<router-link to="/">Home</router-link>
					<router-link v-if="!finished && answeredQuestion" to="/participate">Πάρε Μέρος</router-link>
					<router-link to="/gifts">Δώρα</router-link>
					<router-link to="/winners">Νικητές</router-link>
					<router-link to="/terms">Όροι Διαγωνισμού</router-link>
				</div>
			</div>
		</div>
	</div>
	<div class="main">
		<router-view/>
	</div>
	<div id="tm-footer" class="uk-position-bottom uk-position-fixed">
		<div class="uk-container">
			<ul class="uk-subnav uk-flex-center uk-margin-remove uk-subnav-divider">
				<li><router-link to="/terms">Οροι Διαγωνισμου</router-link></li>
				<li><router-link to="/contact">ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</router-link></li>
			</ul>
		</div>
	</div>
</div>
<!----<CookieConsent :storageType="'localstorage'" />-->
</template>

<script>
import store from '@/store/';
import { useCookiebot } from '@ambitiondev/vue-cookiebot';
//import CookieConsent from './components/CookieConsent';

import { useRoute, useRouter } from 'vue-router'
import { ref, watch, computed,onMounted } from 'vue';
import UIkit from 'uikit';

export default {
	components: {
		//CookieConsent
	},
	
	setup() {
		const { consentBanner } = useCookiebot();
		const openNav = ref('');
		const finished = computed(() => store.getters.getFinished);
		const answeredQuestion = computed(() => store.getters.getAnswertheQuestion);

		const closeOffcanvas = () => {
			UIkit.offcanvas("#offcanvas").hide();
		}
		const toggleMenu = () => {
			if (openNav.value == '') {
				openNav.value = 'open';
			} else {
				openNav.value = '';
			}
		}

		onMounted(() => {
			consentBanner();
		});

		const route = useRoute()
		const router = useRouter()

		const storeInit = () => {
			store.dispatch('init')
			//console.log("finish " + finished.value);
			.then(() =>{
				setTimeout(() => {
					console.log("finish " + finished.value);
					if (route.fullPath === '/') {
						if (finished.value) {
							router.push({ path: 'finish' });
						}
					}
					console.log("Path " + route.fullPath);
				},300)
			
			});
		}

		storeInit();

		watch(route,
			(path) => {
				openNav.value = '';
				if (path.fullPath === '/') {
					if (finished.value) {
						router.push({ path: 'finish' });
					}
				} else if (path.fullPath === '/participate') {
					if (finished.value) {
						router.push({ path: 'finish' });
					}
				} else if (path.fullPath === '/finish') {
					if (!finished.value) {
						router.push({ path: '/' });
					}
				}
			}, {flush: 'pre', immediate: true, deep: true}
		)


		watch(finished,(newValue, oldValue)=>{
			console.log(`Updating from  ${oldValue} to ${newValue}`);
			if (route.fullPath === '/') {
				if (newValue) {
					router.push({ path: 'finish' });
				}
			} else if (route.fullPath === '/participate') {
				if (newValue) {
					router.push({ path: 'finish' });
				}
			} else if (route.fullPath === '/finish') {
				if (!newValue) {
					router.push({ path: '/' });
				}
			}
		})

		return {
			finished,answeredQuestion, openNav, closeOffcanvas, toggleMenu
		}
	},
}
</script>

<style lang="scss">
@import "assets/css/fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

$global-font-family: 'Gotham-Greek','Open Sans', sans-serif;
$global-color: #fff;
$global-link-color: #fff;
$base-heading-color: #fff;
$base-body-color: #fff;
$base-font-family: 'Gotham-Greek';
$base-heading-font-family: 'Gotham-Greek';
$form-label-font-size: 26px;
$form-height: 70px;
$base-body-font-weight: 400;
$base-heading-font-weight: 700;
$container-xsmall-max-width: 710px;
$button-primary-background: #c5c5c5;
$button-primary-color: #000;
$text-primary-color: #fff;
$button-padding-horizontal: 60px;
$button-font-size: 42px;
$button-line-height: 60px;
$base-h1-font-size-m: 63px;
$base-h1-font-size: 48px;
$base-h2-font-size-m: 48px;
$base-h3-font-size: 38px;
$form-radio-checked-icon-color: #00144f;
$form-radio-checked-background: #fff;
$form-color: #000;
$form-focus-color: #000;

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-card() { color: #fff; }
@mixin hook-form() { font-size: 25px; }
@mixin hook-form-label() { line-height: 40px; font-size: 20px; margin-bottom: 5px; display: block; }
@mixin hook-button() { padding: 0 40px; text-transform: none; border-radius: 40px; }

// 4. Import UIkit.
@import "uikit/src/scss/uikit-theme.scss";

:root {
 --crooked-before-bottom: 0%;
}

.header { top: 0; left: 0; right: 0; position: fixed; z-index: 3; width: 100%; height: 80px;  }
.menu-wrap { width: 200px; text-align: center; }
.menu-toggle { padding: 20px 0px 20px 0px; width: 110px;/*background-color: #112143;*/}
.menu-toggle rect{fill:#123f8e !important}
.menu-toggle line{stroke:#123f8e !important;background-color: #005aa9;}
.menu-nav { display: none; outline: none; line-height: 30px; height: 160px; background-color: #005aa9; display: flex; flex-direction: column; justify-items: center; padding: 20px; }
.menu-nav a{font-weight: bold;color: #fff;}
.menu-nav a:hover { color: #dcdcdc; text-decoration: underline; }


/*html.participate, html.contact, html.winners, html.terms{background-color:#fbb04c;}
html { min-height: 100%;background: rgb(56,145,59);background: -moz-linear-gradient(146deg, rgba(56,145,59,1) 0%, rgba(108,190,72,1) 60%, rgba(145,201,97,1) 100%);background: -webkit-linear-gradient(146deg, rgba(56,145,59,1) 0%, rgba(108,190,72,1) 60%, rgba(145,201,97,1) 100%);background: linear-gradient(146deg, rgba(56,145,59,1) 0%, rgba(108,190,72,1) 60%, rgba(145,201,97,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#38913b",endColorstr="#91c961",GradientType=1);	 padding-bottom: 60px; padding-top: 0px; }
html.participate, html.gifts{background: url(assets/bg.jpg) top center #fddd2a no-repeat;  background-size:auto;background-origin:content-box;}
html.home #tm-footer,html.gifts #tm-footer{background-color: #60ba46;}
html.home #tm-footer .uk-subnav > * > :first-child,html.gifts #tm-footer .uk-subnav > * > :first-child{color: #fff;}
html.home #tm-footer .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before,html.gifts #tm-footer .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before{border-left-color: #fff;}
html { background: rgb(36,36,36);background: linear-gradient(142deg, rgba(36,36,36,1) 0%, rgba(86,159,211,1) 0%, rgba(163,211,238,1) 100%);}
*/
.giftsWrapper {background-color: #f08b20;}
html{background-color: #fff;}
/*max-height: 1536px;width: 3850px !important;*/

.kvWrapper {width: 100%; height: 1357px;background-position: center;background-size: cover;background-image: url(assets/photo_big.jpg);}
.kvImage {display: none;}

@media (min-width: 1200px) and (max-width: 1920px) {
	.crooked{margin-top: -175px !important;}
	.mainWrapper{margin-top: -100px;}
}
@media (max-width: 1920px) {
  .kvWrapper {background-image: url(assets/photo.jpg);height: 100%;}
  .kvImage {display: block;width: auto;}
}

.crooked{clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%); height: 120px; width: 100%; margin-top: -75px;}
.crooked_blue{ top: 0px;left: 0; z-index: 1; background: rgb(12,124,193); background: linear-gradient(90deg, rgba(12,124,193,1) 0%, rgba(15,133,199,1) 25%, rgba(23,152,213,1) 50%, rgba(31,174,228,1) 75%, rgba(35,183,234,1) 100%);}
.crooked_grey{ top:-24px;left: 0; z-index: 0; background: linear-gradient(90deg, rgba(192,192,193,1) 0%, rgba(253,253,253,1) 25%, rgba(175,175,175,1) 50%, rgba(125,124,124,1) 75%, rgba(252,252,252,1) 100%);}
.tm-bg-blue, html.participate, html.contact, html.gifts, html.home, html.terms, html.winners, html.finish{ position: relative; z-index: 2; background: rgb(12,124,193); background: linear-gradient(90deg, rgba(12,124,193,1) 0%, rgba(15,133,199,1) 25%, rgba(23,152,213,1) 50%, rgba(31,174,228,1) 75%, rgba(35,183,234,1) 100%);}
.inview{transition: all 1.5s 0.5s ease;}
.tm-card { background-color: rgb(14, 68, 149); border-radius: 40px 0; padding: 40px;}


body.no-footer-bg #bg::after { display: none; }
#nav { width: 100%; z-index: 3; color: #ffffff; max-height: 92px; position: fixed; top: 0; font-size: 20px; text-align: center; transition: max-height 0.45s ease-out, background-color 0.45s ease-out; }
.tm-offcanvas-btn { z-index: 3; position: fixed; top: 0; left: 0; padding: 0 10px; min-height: 60px; }
#nav.open { max-height: 600px; background-color: #0176d3; }
#nav a { outline: none; display: block; color: #000; padding: 5px 20px; font-size: 15px; font-weight: 800; }
#tm-footer { background-color: #ffffff; font-size: 16px; line-height: 16px; padding: 14px 0 !important; text-transform: uppercase; font-weight: 700; z-index: 2; }
#tm-footer .uk-subnav > * > :first-child { font-size: 14px; color: #231f20; padding: 10px 0; font-weight: 700; }
#tm-footer .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before{border-left-color: #231f20;}
.toggler > div { padding: 20px; display: inline-block; width: 200px; }
.toggler > div.closed { background-color: #ed1c24; }
.grecaptcha-badge { z-index: 2; }
.tm-button-tranformed { transform: skew(-20deg, -1deg); }
.tm-contest-dates { font-size: 17px; line-height: 24px; font-weight: 600;color: #233d85;}
.tm-contest-note{ font-size: 12px; line-height: 14px; font-weight: 600;}
.uk-text-danger { color: #ed1c24 !important; }
.uk-radio, .uk-checkbox { height: 26px; width: 26px; background-color: #ffffff;border-radius: 999px; }
.uk-radio:focus, .uk-checkbox:focus { border-color: #222; background-color: #ffffff; }
.uk-radio:checked:focus, .uk-checkbox:checked:focus, .uk-checkbox:indeterminate:focus { background-color:  black;  }
.uk-radio:checked, .uk-checkbox:checked, .uk-checkbox:indeterminate {background-color:  black;}
.uk-radio:checked { background: #231f20; }
.uk-radio:checked:after{ content: ''; width: 10px; height: 10px; border-radius: 50%; display: block; margin-top: 5px; margin-left: 5px; background-color:#fff; }
.uk-checkbox:checked:before{content: '\2713'; display: block;font-size:18px;  margin-left: 5px; color:#fff }
#teconsent { z-index: 3; position: fixed; bottom: 50px; left: 0; }
.tm-background-menu { background-color: #0176d3; height: 80px; }
.uk-text-bold { font-weight: 800; }
label a:hover,label a{color:#233d85;text-decoration: underline;font-weight: bold;}

.tm-color-black{color: black !important;}
.tm-color-blue{color: #00144f !important;}
.tm-color-white{color: #fff !important;}
.tm-color-yellow{color:#ffe507 !important;}
.tm-color-red{color:#c92127 !important;}
.tm-color-blueshadow{color:#00144f !important;text-shadow: 1px 1px 1px #ffe507;}
.trustarc-banner {position: fixed;bottom: 0;width: 100%;z-index: 9;}
.tm-button-secondary{border-top: 10px solid #3e3e3e;border-left: 10px solid white;border-right: 10px solid white;border-bottom: 10px solid white;border-radius: 15px;}

.uk-grid-divider > :not(.uk-first-column)::before {top: 30%;border-left: 5px solid #233d85;height: 70%;}

@media (max-width: 1200px) { 
	.grecaptcha-badge { bottom: 60px !important; }
	#tm-footer .uk-subnav > * > :first-child { font-size: 13px; padding: 10px 14px; }
}

</style>
